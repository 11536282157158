import {
    strich_call
} from './api_core_calls';



//check if portal is online/offline - if online returns 'true'
export function get_status(token, set_token) {

    return true;

    strich_call("GET", "status", token, undefined, set_token)
        .then((response) => {
            //console.log(response)
        })

    return;
}




export function request_email_change(token) {

    strich_call("GET", "request_email_change", token)
        .then((response) => {
            //console.log(response)
        })
     
    return;
}

export function request_paypal_change(token) {

    strich_call("GET", "request_paypal_change", token)
        .then((response) => {
            //console.log(response)
        })

    return;
}

export function get_account_details(token, set_account_details) {

    strich_call("GET", "accounts/account", token)
        .then((response) => {
            //console.log(response)
            set_account_details(response["results"]);
        })

    return;
}

export function get_queue(token, set_ai_queue) {

    strich_call("GET", "ai/queue", token)
        .then((response) => {
            set_ai_queue(response["results"]);
        })

    return;
}

export async function add_to_queue(token, queue_item) {

    let postdata = JSON.stringify(Object.assign({}, queue_item))
    let result = await strich_call("POST", "ai/queue", token, postdata)

    return result;
}

export function get_queue_item(token, queue_id, set_selected_queue_item) {

    strich_call("GET", "ai/queue?queue_id=" + queue_id, token)
        .then((response) => {
            set_selected_queue_item(response["results"][0]);
        })

    return;
}

export function get_ai_to_process(token, set_ai_to_process) {

    strich_call("GET", "ai/to_process" , token)
        .then((response) => {
            //console.log(response)
            set_ai_to_process(response["results"]);
        })

    return;
}

export function get_ai_styles(token, set_ai_styles) {

    strich_call("GET", "ai/styles", token)
        .then((response) => {
            set_ai_styles(response["results"]);
        })

    return;
}